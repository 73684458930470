import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './routes/home.jsx';
import './sass/styles.scss';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/countdown" element={<Home />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('app'),
);
