import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Ajax from '../../managers/Ajax';
import Throbber from '../throbber';
import 'react-toastify/dist/ReactToastify.css';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerFirstname: '',
      registerLastname: '',
      registerPhone: '',
      registerEmail: '',
      isLoading: false,
      dropId: process.env.DROP_ID,
      hasSignedUp: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.validateForm(e);
    }
  }

  formatPayload = () => {
    // should probably add some error handling/validation here
    const { registerFirstname } = this.state;
    const { registerLastname } = this.state;
    const { registerPhone } = this.state;
    const { registerEmail } = this.state;
    const { dropId } = this.state;
    if (registerFirstname === ''
    || registerLastname === ''
    || registerPhone === ''
    || registerEmail === '') {
      toast.error('Please complete all fields.');
      return false;
    }
    if (!emailPattern.test(registerEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    const payload = {
      firstName: registerFirstname,
      lastName: registerLastname,
      telephone: registerPhone,
      email: registerEmail,
      location: '',
      signUpUrl: window.location.href,
      dropId,
    };
    return payload;
  }

  submitForm = async (e) => {
    e.preventDefault();
    const { dropId } = this.state;
    const payload = this.formatPayload();
    if (payload) {
      this.setState({
        isLoading: true,
      });
      try {
        // const register = await Ajax.post('https://localhost:7180/api/usersignup', payload);
        const register = await Ajax.post('https://ethos-api-test.azurewebsites.net/api/usersignup', payload);
        if (register) {
          this.setState({
            isLoading: false,
            hasSignedUp: true,
          });
          localStorage.setItem('EthosCountdown', dropId);
        } else {
          toast.error('Oops! Something went wrong. Please try again later');
        }
      } catch (error) {
        toast.error(error.message || 'Oops! Something went wrong. Please try again later');
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  componentDidMount() {
    const { dropId } = this.state;
    if (localStorage.getItem('EthosCountdown') && localStorage.getItem('EthosCountdown') === dropId) {
      this.setState({
        hasSignedUp: true,
      });
    }
    console.log(process.env.DROP_ID);
  }

  render() {
    const { registerFirstname } = this.state;
    const { registerLastname } = this.state;
    const { registerPhone } = this.state;
    const { registerEmail } = this.state;
    const { isLoading } = this.state;
    const { hasSignedUp } = this.state;
    return (
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          pauseOnHover />
        {hasSignedUp
          ? (
            <div className="register-container">
              <h2 className="register-header">
                {'Thanks for signing up! Keep your eye on your inbox! '}
                {'We\'ll notify you as soon as the drop lands!'}
                <br />
                <br />
                Cheers!
                <br />
                The Ethos team.
              </h2>
            </div>
          )
          : (
            <div className="register-container">
              {isLoading
              && <Throbber throbberText="Signing you up! One second..." />}
              <h2 className="register-header">
                Sign up now!
              </h2>
              <div className="form-container">
                <div className="form-section">
                  <label className="label-block" htmlFor="registerFirstname">First name:</label>
                  <input
                  className="input-block"
                  id="registerFirstname"
                  type="text"
                  value={registerFirstname}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
                </div>
                <div className="form-section">
                  <label className="label-block" htmlFor="registerLastname">Last name:</label>
                  <input
                  className="input-block"
                  id="registerLastname"
                  type="text"
                  value={registerLastname}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
                </div>
                <div className="form-section">
                  <label className="label-block" htmlFor="registerPhone">Phone number:</label>
                  <input
                  className="input-block"
                  id="registerPhone"
                  type="text"
                  value={registerPhone}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
                </div>
                <div className="form-section">
                  <label className="label-block" htmlFor="registerEmail">Email:</label>
                  <input
                  className="input-block"
                  id="registerEmail"
                  type="email"
                  value={registerEmail}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
                </div>
                <div className="form-cta">
                  <button type="button" className="button" onClick={(e) => this.submitForm(e)}>Submit</button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default Register;
