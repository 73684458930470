import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Throbber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { throbberText } = this.props;
    return (
      <div className="throbber">
        <div className="throbber-content">
          <p>{throbberText || 'Loading...'}</p>
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

Throbber.propTypes = {
  throbberText: PropTypes.string,
};

export default Throbber;
