import React, { Component } from 'react';
import Countdown from 'react-countdown';
import CookieConsent from 'react-cookie-consent';
import Register from '../components/register/index.jsx';
import background1 from '../images/fluevog/bg1.jpg';
import background2 from '../images/fluevog/bg2.jpg';
import background3 from '../images/fluevog/bg3.jpg';
import homeLogo from '../images/fluevog/logo.png';
import Footer from '../components/footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heroMessage: null,
      currentBackground: '',
      countdownData: Date.now() + 5550000000,
    };
    this.getHeroHeight = this.getHeroHeight.bind(this);
  }

  componentDidMount() {
    const that = this;
    this.getHeroHeight();
    window.addEventListener('resize', that.getHeroHeight);
    this.setState({
      currentBackground: background1,
    });
    this.startBackgroundCycle();
  }

  startBackgroundCycle = () => {
    let count = 1;
    const that = this;
    function increaseTimer() {
      if (window.innerWidth > 750) {
        if (count === 1) {
          that.setState({
            currentBackground: background1,
          });
        }
        if (count === 2) {
          that.setState({
            currentBackground: background2,
          });
        }
        if (count === 3) {
          that.setState({
            currentBackground: background3,
          });
        }
        count += 1;
        if (count > 3) {
          count = 1;
        }
        setTimeout(() => {
          increaseTimer();
        }, 10000);
      }
    }
    increaseTimer();
  }

  getHeroHeight() {
    const heroElement = document.getElementById('homeHero');
    const windowHeight = window.innerHeight;
    heroElement.style.height = windowHeight + 'px';
    this.setState({
      heroMessage: '',
    });
  }

  render() {
    const { currentBackground } = this.state;
    const { heroMessage } = this.state;
    const { countdownData } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        // Render a completed state
        return 'Countdown Complete';
      }
      // Render a countdown
      return (
        <div>
          <div className="countdown-item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div id="homeHero" className="hero">
        <div className="hero-tagline">
          <h2>
            {heroMessage}
          </h2>
          <p className="hero-subtitle">Sign up to get notified as soon as the NFT drops!</p>
          <div className="hero-countdown">
            <Countdown
            date={countdownData}
            renderer={renderer} />
          </div>
        </div>
        <div className="hero-signup-container">
          <img className="hero-logo" src={homeLogo} alt="home logo" width="200" />
          <div className="hero-signup">
            <Register />
          </div>
        </div>
        <div className="hero-banner">
          <div className="hero-banner-inner" style={{ backgroundImage: `url(${currentBackground})` }}></div>
        </div>
        {/* <div className="hero-overlay"></div> */}

        <Footer />
        <CookieConsent
          // debug // Debug mode forces cookie banner every refresh.
          style={{ background: '#777', color: '#FFF' }}
          buttonStyle={{
            padding: '10px', background: '#00AEEF', color: '#fff', borderRadius: '5px',
          }}>
          <a style={{ color: 'white' }} href="https://www.ethosnft.com">ethos</a>
          {' '}
          uses cookies to provide necessary website functionality,
          improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
          <a style={{ color: 'white' }} target="_blank" href="https://account.ethosnft.com/privacy/" rel="noreferrer">our privacy policy</a>
          {' '}
          and our cookies usage.
          
        </CookieConsent>
      </div>
    );
  }
}

export default Home;
