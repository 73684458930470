import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <footer className="footer">
        Powered by
        {' '}
        <a href="https://www.ethosnft.com" target="_blank" rel="noreferrer">ethos.</a>
      </footer>
    );
  }
}

export default Footer;
